import React, { lazy, Suspense }  from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/App.css";
import NavMenu from "./components/NavMenu";
import Loader from "./components/Loader";
import { InstallPWA } from "./components/InstallPrompt";
const Feed = lazy(() => import("./screens/Feed"))
const Timetable = lazy(() =>  import("./screens/Timetable"));
const Media = lazy(() => import("./screens/Media"));

function onTelegramAuth(user: any) {
  alert('Logged in as ' + user.first_name + ' ' + user.last_name + ' (' + user.id + (user.username ? ', @' + user.username : '') + ')');
}

function App() {
  return (
    <Router>
      <InstallPWA />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Feed />} />
          <Route path="/timetable" element={<Timetable />} />
          <Route path="media" element={<Media />} />
        </Routes>
        <NavMenu />
      </Suspense>
    </Router>
  );
}

export default App;
